import React, {useState} from 'react';
import {Document, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import Logo from './VT_AOE.jpg'

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        marginLeft: 40,
        marginRight: 50,
        display: 'flex',
        fontSize: '10px'
    },
    section: {
        flexGrow: 1,
        fontSize: 15,
        borderBottom: '1px solid rgb(25,25,25)',
        width: "90%",
        marginBottom: 5,
        marginTop: 15,
        paddingRight: 50
    },
    text: {
        margin: 5
    },
    list: {
        display: "flex",
        flexDirection: "column",
        margin: 5
    },
    image: {
        marginTop: 40,
        width: 500,
        alignSelf: "flex-end"
    },
    data: {
        backgroundColor: "rgb(243,243,243)"
    }
});
const formatDate = (date) => {
    return new Date(date ?? Date.now()).toLocaleDateString()
}
// Create Document Component
const SurveyPDFTemplate = ({data}) => {
    const [year, setYear] = useState(new Date())

    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <Image source={Logo} style={styles.image}/>
                    <Text>Home Language Survey {year.getFullYear()}</Text>
                    <View>
                        <Text style={styles.section}>General Information</Text>
                        <Text style={styles.text}>Submit Date: <Text style={styles.data}>{formatDate()}</Text></Text>
                        <Text style={styles.section}>Student Information</Text>
                        <Text style={styles.text}>First Name: <Text style={styles.data}> {data.firstName}</Text></Text>
                        <Text style={styles.text}>Last Name: <Text style={styles.data}>{data.lastName}</Text></Text>
                        <Text style={styles.text}>DOB: <Text style={styles.data}>{formatDate(data.birthDate)}</Text></Text>
                        <Text style={styles.text}>Gender: <Text style={styles.data}>{data.sex}</Text></Text>
                        <Text style={styles.text}>Country of Birth: <Text style={styles.data}>{data.birthCountry.label}</Text></Text>
                        <Text style={styles.text}>US Entry Date: <Text style={styles.data}>{formatDate(data.usEntryDate)}</Text></Text>
                        <Text style={styles.text}>Date Student first began in any US School: <Text
                            style={styles.data}>{formatDate(data.firstUSEnrollmentDate)}</Text></Text>
                    </View>

                    <View>
                        <Text style={styles.section}>Family Information</Text>
                        <Text style={styles.text}>Specific language:
                            <View>
                                {data.specificLanguage ?
                                    <Text style={styles.data}>{data.specificLanguage.label}</Text>
                                    : "None"}
                            </View>
                        </Text>
                        <Text style={styles.text}>Languages spoken at home: </Text>

                        <View style={styles.list}>
                            {data.spokenInHome ?

                                data.spokenInHome.map((lang, i) => (
                                    <Text key={i} style={styles.data}>{lang.label}</Text>
                                ))

                                : "None"}
                        </View>
                        <Text style={styles.text}>Languages most often spoken to child:</Text>
                        <View style={styles.list}>
                            {data.usedMostOftenWithChild ?

                                data.usedMostOftenWithChild.map((lang, i) => (
                                    <Text key={i} style={styles.data}>{lang.label}</Text>
                                ))
                                : "None"}
                        </View>
                        <Text style={styles.text}>Languages currently most-used at home:</Text>

                        <View style={styles.list}>
                            {data.favored ?

                                data.favored.map((lang, i) => (
                                    <Text key={i} style={styles.data}>{lang.label}</Text>
                                ))

                                : "None"}
                        </View>
                        <Text style={styles.text}>Languages native to parent:</Text>

                        <View style={styles.list}>
                            {data.parentLanguages ?

                                data.parentLanguages.map((lang, i) => (
                                    <Text key={i} style={styles.data}>{lang.label}</Text>
                                ))

                                : "None"}
                        </View>
                        <Text style={styles.text}>Languages first learned by child:</Text>

                        <View style={styles.list}>
                            {data.firstLearned ?

                                data.firstLearned.map((lang, i) => (
                                    <Text key={i} style={styles.data}>{lang.label}</Text>
                                ))

                                : "None"}
                        </View>
                    </View>

                    <View>
                        <Text style={styles.section}>School Information</Text>
                        <Text style={styles.text}>Enrolling at: <Text style={styles.data}>{data.enrollingAt.label}</Text></Text>
                        <Text style={styles.text}>Date of enrollment: <Text
                            style={styles.data}>{formatDate(data.enrollingAtDate)}</Text></Text>
                        <Text style={styles.text}>Entering Grade: <Text style={styles.data}>{data.enrollingGrade.label}</Text></Text>
                        <Text style={styles.text}>Screened: <Text
                            style={styles.data}> {data.wasScreened === "Y" ? "Yes" : "No"}</Text></Text>
                        {data.wasScreened === "Y" ?
                            <>
                                <Text style={styles.text}>Name of screener: <Text style={styles.data}>{data.screenerName}</Text></Text>
                                <Text style={styles.text}>Date of screening: <Text
                                    style={styles.data}>{formatDate(data.screenedDate)}</Text></Text>
                            </>
                            :
                            <Text style={styles.text}>Meets ESSA definition "Immigrant Children and Youth": <Text
                                style={styles.data}>{data.isImmigrant}</Text></Text>
                        }
                    </View>
                </Page>
            </Document>
        </>
    )
};
export default SurveyPDFTemplate

