import React from "react";
import {Breadcrumb, Dropdown} from 'react-bootstrap'
import {Link, Route, Switch} from "react-router-dom"
import Survey from "./component/survey/Survey";
import ProcessSurveys from "./component/process/ProcessSurveys";
import ProcessCrossReference from './component/compare/ProcessCrossReference'
import StudentAndEdFusion from "./component/student/Forms/StudentAndEdFusion";
import VermontLogo from './VermontLogo'
import Page_404 from './Page_404'
import StudentSearch from './component/student/Search/StudentSearch'
import LanguageTable from "./component/isolanguagetable/LanguageTable";
// MSAL Auth
import {SignInButton} from "./component/survey/SignInButton";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated} from "@azure/msal-react";
import {SignOutButton} from "./component/survey/SingOutButton";
import ProfileContent from "./component/survey/ProfileContent";

const Layout = () => {
	// Authentication credentials on highest level component
	const isAuthenticated = useIsAuthenticated()
	return (
		<div>
			<div className="container mb-0">
				<div className="row">
					<Breadcrumb className={'col-12 p-0'}>
						<VermontLogo />
						<div className="d-flex justify-content-end align-items-center col-6">
							<UnauthenticatedTemplate>
								<SignInButton></SignInButton>
							</UnauthenticatedTemplate>
							<AuthenticatedTemplate>
								<Dropdown >
									<Dropdown.Toggle variant="secondary" id="menu-dropdown">
										Menu
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item as={Link} style={{ textDecoration: "none" }} className="pr-3" to={"/"}  >Home</Dropdown.Item>
										<Dropdown.Item as={Link} style={{ textDecoration: "none" }} className="pr-3" to={"/process"}>Review Surveys</Dropdown.Item>
										<Dropdown.Item as={Link} style={{ textDecoration: "none" }} className="pr-3" to={"/studentsearch"}>Student Search</Dropdown.Item>
										<Dropdown.Item as={Link} style={{ textDecoration: "none" }} className="pr-3" to={"/languagetable"}>ISO Languages</Dropdown.Item>
										<Dropdown.Divider />
										<ProfileContent />
										<Dropdown.Divider />
										<Dropdown.Item as={Link} style={{ textDecoration: "none" }} className="pr-3" to={"#"}><SignOutButton /></Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</AuthenticatedTemplate>
						</div>
					</Breadcrumb>
				</div>
				<UnauthenticatedTemplate>
					< Switch >
						<Route path="/" component={Survey} exact />
						<Route path="/*" component={Page_404} exact />
					</Switch>
				</UnauthenticatedTemplate>
				<AuthenticatedTemplate>
					< Switch >
						<Route path="/" component={Survey} exact />
						< Route path="/studentSearch" component={StudentSearch} />
						<Route path="/process" component={ProcessSurveys} />
						<Route path="/form/:id" component={ProcessCrossReference} />
						<Route path="/student/:id" component={StudentAndEdFusion} />
						<Route path="/languagetable" component={LanguageTable} />
					</Switch >
				</AuthenticatedTemplate>
			</div >
		</div >
	)
}
export default Layout

